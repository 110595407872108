/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import { Link, StaticQuery, graphql, useStaticQuery } from "gatsby"

import tw, { GlobalStyles } from "twin.macro"
import styled from "styled-components"
import { motion } from "framer-motion"
import { IntersectionObserver } from "../components/Motion/IntersectionObserver"
import { FadeInUpBox } from "../components/Motion/FadeInUpBox"
import SelectLanguage from "../components/SelectLanguage"
import CookieConsent, { Cookies } from "react-cookie-consent"
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"

import "@fontsource/oswald"
import "@fontsource/karla"
import "@fontsource/great-vibes"

import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown"
import bummelSrc from "../images/bummel.svg"
import "tailwindcss/dist/base.css"
import "../styles/globalStyles.css"
import "../styles/styles.css"

import PropTypes from "prop-types"

import "./layout.css"

import BackgroundSection from "../components/StyledBackgroundSection"
import heroLogoImageSrc from "../images/herologo-dk.svg"

import Light, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../components/headers/light.js"

const Layout = ({
  children,
  showHeader,
  imageData,
  title,
  location,
  i18nMessages,
}) => {
  // Styled Components
  const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-10`
  const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`

  const StyledHeader = styled(Light)`
    ${tw`pt-4 max-w-none`}
    ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
      ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
    }
    ${NavToggle}.closed {
      ${tw`text-gray-100 hover:text-primary-500`}
    }
  `

  const OneColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col`
  const OneColumnLow = tw.div`pt-6 flex justify-between items-center flex-col`
  const CenterColumn = tw.div`flex flex-col items-center`

  const Bummel = tw.div`absolute bottom-0 z-10 inset-x-0 w-20  mx-auto -mt-1`

  const Heading = styled.h1`
    ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
    span {
      ${tw`inline-block mt-2`}
    }
  `

  const Title = styled.h1`
    ${tw`
      text-3xl text-center lg:text-left sm:text-4xl 
      lg:text-5xl xl:text-6xl
      font-semibold
      font-display

      uppercase
      text-gray-100 leading-none`}
    span {
      ${tw`inline-block`}
    }
  `

  const PrimaryAction = tw.button`
    font-karla
    px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 
    bg-gray-100 text-primary-600  
    rounded-full shadow-md transition-all duration-300 hocus:bg-primary-500 
    hocus:text-gray-100 focus:shadow-outline`

  const LinkClass = `
    text-sm  xl:text-base my-2 lg:mx-6 lg:my-0
    uppercase 
    text-gray-700
    hover:text-primary-700
    hover:border-primary-700 
    lg:hover:text-gray-100
    lg:hover:border-gray-100 
    lg:text-gray-100
    font-normal
    tracking-wide transition-all  duration-300
    border-b-2 border-transparent hover:border-primary-500 
  `

  const navLinks = [
    <NavLinks key={1}>
      <Link to="/dk" className={LinkClass}>
        Startside
      </Link>
      <Link to="/dk/speisekarte/" className={LinkClass}>
        Menu
      </Link>
      <Link to="/dk/galerie/" className={LinkClass}>
        Galleri
      </Link>

      <Link to="/dk/contact/" className={LinkClass}>
        Kontakt &amp; Impressum
      </Link>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/contact">Reservationer</PrimaryLink>
    </NavLinks>,
  ]

  useEffect(() => {
    // Update the document title using the browser API
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  })

  const HeaderContent = () => {
    return showHeader ? (
      <OneColumn>
        <IntersectionObserver>
          <FadeInUpBox>
            <CenterColumn>
              <Heading>
                <img
                  src={heroLogoImageSrc}
                  alt="Cafe Milch und Zucker"
                  width="300"
                  height="180"
                />
              </Heading>
                  <PrimaryAction>Mandag til fredag: 09:00 til 17:30</PrimaryAction>

              <PrimaryAction>Lørdag og søndag: 10:00 til 18:00</PrimaryAction>
            </CenterColumn>
          </FadeInUpBox>
        </IntersectionObserver>
      </OneColumn>
    ) : (
      <OneColumnLow>
        <IntersectionObserver>
          <FadeInUpBox>
            <CenterColumn>
              <Title>{title}</Title>
            </CenterColumn>
          </FadeInUpBox>
        </IntersectionObserver>
      </OneColumnLow>
    )
  }

  const Cookie = tw`
    font-karla
    
    bg-gray-300
    bg-opacity-95
    h-1/2
    lg:h-1/3`

  const CookieButton = tw`
    font-karla
    rounded-lg
    bg-green-700
    text-gray-100
    py-2 px-6
    text-sm
    shadow-md
    `

  const CookieButtonDecline = tw`
    font-karla
    rounded-lg
    bg-gray-600
    text-gray-100
    py-2 px-6
    text-sm
    shadow-md
    `

  const BackgroundClasses = showHeader
    ? "my-element relative border-white bg-center bg-cover h-screen min-h-144 lg:border-12"
    : "relative border-white bg-center bg-cover min-h-96 lg:border-12"

  const variants = {
    hidden: {
      opacity: 0.0,
    },
    visible: {
      opacity: 1,
    },
  }

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={data => {
        const url = location.pathname
        const { langs, defaultLangKey } = data.site.siteMetadata.languages
        const langKey = getCurrentLangKey(langs, defaultLangKey, url)
        const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, "/")
        const langsMenu = getLangs(
          langs,
          langKey,
          getUrlForLang(homeLink, url)
        ).map(item => ({
          ...item,
          link: item.link.replace(`/${defaultLangKey}/`, "/"),
        }))
        return (
          <IntlProvider locale={langKey} messages={i18nMessages}>
            {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}
            <GlobalStyles />

            <CookieConsent
              enableDeclineButton
              flipButtons
              location="bottom"
              buttonText="Accepter"
              declineButtonText="Nedgang"
              style={Cookie}
              buttonStyle={CookieButton}
              buttonWrapperClasses="p-4 mx-auto"
              declineButtonStyle={CookieButtonDecline}
              cookieName="gatsby-gdpr-google-analytics"
              onAccept={() => {
                // in your cookie banner
                initializeAndTrack(location)
              }}
            >
              <div
                css={tw`font-karla text-sm text-gray-800 m-auto w-64 lg:w-128 pt-6`}
              >
                Vi elsker ikke kun kage, vi elsker også cookies! De smager som
                kage og hjælper os med at forbedre webstedet for dig. Deltag i
                den store cookie-bevægelse nu, og accepter cookies!
              </div>
            </CookieConsent>
            <BackgroundSection
              className={BackgroundClasses}
              showHeader={showHeader}
              imageData={imageData}
            >
              <OpacityOverlay />
              <HeroContainer>
                <StyledHeader links={navLinks} langs={langsMenu} />
                <HeaderContent />
              </HeroContainer>

              {showHeader ? (
                <>
                  <Bummel style={{ marginBottom: -27 }}>
                    <img
                      src={bummelSrc}
                      alt="Bitte weiterlesen"
                      width="90"
                      height="24.96"
                    />
                  </Bummel>
                  <Bummel style={{ paddingLeft: 36 }}>
                    <FaChevronDown className="" />
                  </Bummel>
                </>
              ) : (
                ""
              )}
            </BackgroundSection>
            <div>
              <main>{children}</main>
              <footer
                style={{
                  marginTop: `2rem`,
                }}
              >
                {/* © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a> */}
              </footer>
            </div>
          </IntlProvider>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
